import React, { FC } from 'react';

export const WarningIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="#292A2D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.759 1.948a.867.867 0 0 0-1.518 0l-5.633 10.4a.866.866 0 0 0 .759 1.285h11.266a.867.867 0 0 0 .759-1.285l-5.633-10.4Z"
      />
      <path
        stroke="#292A2D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8 11.033a.216.216 0 0 1 .217.217m-.434 0A.217.217 0 0 1 8 11.033m0 .434a.217.217 0 0 1-.217-.217m.434 0a.217.217 0 0 1-.217.217M8 9.3V5.4"
      />
    </svg>
  );
};
